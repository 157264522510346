<template>
	<read-template module="LEGAL" />
</template>
<script>
import ReadTemplate from '@/components/agreement/readDocument/ReadDocument.vue';

export default {
	name: 'LegalPrecontractTemplateView',
	components: { ReadTemplate },
};
</script>
